import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import purpleIcon from '/src/dummy-data/images/purpleIcon'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const Callout = loadable(() => import('/src/components/Cards/Callout'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const ItemList = ({
    backgroundColour,
    blockSettings,
    titleArea,
    addImages,
    addTitles,
    addDescriptions,
    itemsRepeater,
    addExtended,
    extendedContent,
    addCta,
    callToAction,
    utm
}) => {
    const paddingSize = blockSettings?.paddingSize

    const componentContent = (
        <div className="row c-item-list__content-row">
            {itemsRepeater.map((node, index) => {
                return (
                    <div key={index} className="col-lg-4 c-item-list__item">
                        {addImages && <Image data={node.image} className={'c-item-list__item-image'} />}

                        {addTitles && <div className="c-item-list__item-title">{node.title}</div>}

                        {addDescriptions && <div className="c-item-list__item-description">{node.description}</div>}
                    </div>
                )
            })}
        </div>
    )

    return (
        <Section variant={backgroundColour} size={paddingSize} titleArea={titleArea} className={'c-item-list'}>
            {itemsRepeater && componentContent}

            {addExtended &&
                extendedContent && ( // TODO: Used on the 'rewards for' landing pages only - consider removing
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <Callout>{parse(extendedContent)}</Callout>
                        </div>
                    </div>
                )}

            {addCta && callToAction && (
                <CtaContainer align={'centered'}>
                    <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                </CtaContainer>
            )}
        </Section>
    )
}

ItemList.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    addImages: PropTypes.bool,
    addTitles: PropTypes.bool,
    addDescriptions: PropTypes.bool,
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object,
            title: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired,
    addExtended: PropTypes.bool,
    extendedContent: PropTypes.string,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ItemList.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    addImages: true,
    addTitles: true,
    addDescriptions: true,
    itemsRepeater: [
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        },
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        },
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        }
    ],
    addExtended: false,
    addCta: false,
    callToAction: {
        url: '#',
        title: 'Button title'
    }
}

export default ItemList

export const query = graphql`
    fragment ItemListForPageBuilder on WpPage_Acfpagebuilder_Layouts_ItemList {
        fieldGroupName
        blockSettings {
            paddingSize
        }
        backgroundColour
        addTitleArea
        title
        description
        addImages
        addTitles
        addDescriptions
        itemsRepeater {
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 120)
                    }
                }
                altText
                title
            }
            title
            description
        }
        addExtended
        extendedContent
        addCta
        callToAction {
            url
            title
            target
        }
    }
`
